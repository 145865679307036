import React from 'react';
import { useWindowSize } from 'react-use';

interface ArticleSection {
  id: string;
  label: string;
}

interface ArticleMenuProps {
  sections: ArticleSection[];
}

const ArticleMenu: React.FC<ArticleMenuProps> = ({ sections }) => {
  const { width } = useWindowSize();
  const headerOffset = width < 768 ? 62 : 72;

  const scrollTo = (id: string) => {
    const el = document.getElementById(id);
    if (!el) {
      return;
    }

    window.scrollTo({ top: el.offsetTop - headerOffset, behavior: 'smooth' });
  };
  return (
    <ul className="sticky p-12 space-y-6 w-72 lg:w-96 text-dark-grey top-28 bg-tertiary">
      {sections.map(({ id, label }) => (
        <li key={id}>
          <button
            className="flex items-center justify-start w-full text-lg font-semibold transition-colors md:text-left hover:text-primary-red focus:text-primary-red focus:outline-none focus:ring-0"
            onClick={() => scrollTo(id)}
          >
            <span className="mr-6">{label}</span>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="10"
                fill="none"
                viewBox="0 0 9 10"
              >
                <path
                  fill="currentColor"
                  d="M8.295 4.1a1.04 1.04 0 010 1.8L1.665 9.73c-.693.4-1.56-.1-1.56-.901V1.172c0-.8.867-1.3 1.56-.9l6.63 3.827z"
                ></path>
              </svg>
            </span>
          </button>
        </li>
      ))}
    </ul>
  );
};

export default ArticleMenu;

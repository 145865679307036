import React from 'react';

const Select: React.FC<
  React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  >
> = ({ children, ...props }) => (
  <div className="relative">
    <select className="appearance-none u-input" {...props}>
      {children}
    </select>
    {/* <svg
      width="13"
      height="10"
      className="absolute right-5 top-5"
      viewBox="0 0 13 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.36553 8.70075C6.95392 9.31862 6.04609 9.31863 5.63448 8.70075L1.08174 1.86659C0.621306 1.17543 1.11678 0.25 1.94726 0.25L11.0527 0.249999C11.8832 0.249999 12.3787 1.17543 11.9183 1.86658L7.36553 8.70075Z"
        fill="black"
      />
    </svg> */}
  </div>
);

export default Select;

import React from 'react';

interface LegalHeroProps {
  lead: string;
  title: string;
}

const LegalHero: React.FC<LegalHeroProps> = ({ lead, title }) => (
  <section
    className="py-20 bg-right bg-cover md:py-36"
    style={{ backgroundImage: 'url(/images/legal-hero.jpg' }}
  >
    <div className="u-container">
      <div className="text-sm text-tertiary opacity-60 mb-3.5">Last Updated: {lead}</div>
      <h1 className="max-w-md text-white u-h1">
        {title}
      </h1>
    </div>
  </section>
);

export default LegalHero;

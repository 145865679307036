import { graphql } from 'gatsby';
import moment from 'moment';
import React from 'react';
import { useWindowSize } from 'react-use';
import Select from '../components/atoms/Select';
import Layout from '../components/base/Layout';
import Seo from '../components/base/Seo';
import ArticleMenu from '../components/organisms/ArticleMenu';
import LegalHero from '../components/organisms/LegalHero';

const LegalPage = ({ data: { page } }) => {
  const { width } = useWindowSize();
  const headerOffset = width < 768 ? 62 : 72;

  const scrollTo = (id: string) => {
    const el = document.getElementById(id);
    if (!el) {
      return;
    }

    window.scrollTo({ top: el.offsetTop - headerOffset, behavior: 'smooth' });
  };

  return (
    <Layout>
      <Seo post={page} />

      <LegalHero
        title={page.title}
        lead={moment.utc(page.dateGmt).format('DD/MM/YYYY')}
      />

      <div className="flex flex-col justify-between max-w-screen-xl my-16 u-container md:my-32 md:flex-row ">
        <div className="mb-8 md:hidden">
          <Select
            onChange={(e) => {
              if (!window) {
                return;
              }

              scrollTo(e.target.value);
            }}
            className="w-full px-4 py-3 font-semibold border outline-none appearance-none border-grey-400"
          >
            {page.legal.legalSections.map((section, index) => (
              <option key={index} value={index}>
                {section.label}
              </option>
            ))}
          </Select>
        </div>

        <div className="hidden mb-16 text-left md:mb-0 md:block">
          <ArticleMenu
            sections={page.legal.legalSections.map(({ label }, index) => ({
              label,
              id: index,
            }))}
          />
        </div>

        <div className="max-w-full md:ml-8 w-article">
          {page.legal.legalSections.map((section, index) => (
            <div
              key={index}
              id={index}
              className="mb-8 prose"
              dangerouslySetInnerHTML={{
                __html: section.content,
              }}
            />
          ))}
        </div>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query LegalQuery($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      slug
      dateGmt
      legal {
        legalSections {
          label
          content
        }
      }
      ...SEO
    }
  }
`;

export default LegalPage;
